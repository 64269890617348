/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const Rajdhani_300Light = require('./Rajdhani_300Light.ttf');
export const Rajdhani_400Regular = require('./Rajdhani_400Regular.ttf');
export const Rajdhani_500Medium = require('./Rajdhani_500Medium.ttf');
export const Rajdhani_600SemiBold = require('./Rajdhani_600SemiBold.ttf');
export const Rajdhani_700Bold = require('./Rajdhani_700Bold.ttf');
